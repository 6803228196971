/* eslint-disable guard-for-in */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { ConstRestData } from 'src/app/const-data/rest';
import { RemoteServiceModulesComponent } from './sub-sections/remote-service-modules/remote-service-modules.component';
import { ManagedSystemServicesComponent } from './sub-sections/managed-system-services/managed-system-services.component';
import { SwUpdateComponent } from './sub-sections/sw-update/sw-update.component';
import { SystemUpgradesComponent } from './sub-sections/system-upgrades/system-upgrades.component';
import uiConfig from 'src/app/config/ui-config.json';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-define-services',
  templateUrl: './define-services.component.html',
  styleUrls: ['./define-services.component.scss']
})
export class DefineServicesComponent implements OnInit, AfterViewInit {

  REST_URLS = ConstRestData.restUrls;

  defineServicesForm: FormGroup;

  @ViewChild('first-element', { static: false }) firstElement!: ElementRef;
  @ViewChild(RemoteServiceModulesComponent) remoteServicesForm!: RemoteServiceModulesComponent;
  @ViewChild(ManagedSystemServicesComponent) managedSystemServiceForm!: ManagedSystemServicesComponent;
  @ViewChild(SystemUpgradesComponent) systemUpgradesForm!: SystemUpgradesComponent;
  @ViewChild(SwUpdateComponent) swUpdateForm!: SwUpdateComponent;



  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  constructor(private formBuilder: FormBuilder, private dataTransferService: DataTransferService,
    protected formDataService: FormDataService) {

    this.defineServicesForm = this.formBuilder.group({
      costOfOneTimeServices: this.formBuilder.group({
        setUpInitMobilization: [null, Validators.required],
        inventoryBaselineService: [null, Validators.required],
        lifeCycleInformationService: [null, Validators.required],
        assetOptServiceAnalysis: [null, Validators.required],
        systemAuditLCS: [null, Validators.required]
      }),
      informationService: this.formBuilder.group({
        dDTimesPerYear: [null, Validators.required],
        rBInfoService: [null, Validators.required],
        dDStandardLISComp: [null, Validators.required],
        alternativeCost: [null, Validators.required],
        orderID: [null],
        textFieldCurrentCostPerYear: [null]
      }),
      contractManagement: this.formBuilder.group({
        rBContractManagement: [null, Validators.required],
        basicEffortStandard: [null],
        basicEffortExtAvailability: [null],
        percentageOfTotalLcsCost: [null, Validators.required],
        costForAlternativeManagement: [null, Validators.required],
        textFieldCurrentCostPerYearContractManagement: [null]
      }),
      productServiceAndSupport: this.formBuilder.group({
        checkBoxOnlineSupport: [{ value: null, disabled: true }],
        checkBoxIndustryServiceCard: [null, Validators.required],
        cBSISC36: [null, Validators.required],
        rB36prioPrem: [null, Validators.required],
        dD36Cases: [null, Validators.required],
        dD36Qty: [null, Validators.required],
        cBSISCperYear: [null, Validators.required],
        rBperYearPrioPrem: [null, Validators.required],
        dDperYearCases: [null, Validators.required],
        dDperYearQty: [null, Validators.required],
        dDperYearCases15: [null, Validators.required],
        dDperYearQty15: [null, Validators.required],
        cBSISCatUpgrades: [null, Validators.required],
        rBSISCyearsWithUpgrades: [null, Validators.required],
        dDyearsWithUpdatesCases: [null, Validators.required],
        dDYearsWithUpdatesQty: [null, Validators.required]
      }),
      onCallServiceControlCenter: this.formBuilder.group({
        checkBoxOnCallService: [null, Validators.required],
        costPerYearOnCallService: [null, Validators.required],
        implementationCostOnCallService: [null, Validators.required]
      }),
      remoteServiceModules: this.formBuilder.group({
        remoteServicesCostIncreaseRate: [null],
        remoteServiceRiskAddition: [null],
        newRemoteServiceName: [null],
        newRemoteServiceDescription: [null],
        newRemoteServiceMlfb: [null],
        newRemoteServiceQuantity: [null],
        newRemoteServicePeriod: [null]
      }),
      preventiveMaintenance: this.formBuilder.group({
        prevMainSelection: [null, Validators.required],
        prevMainPerYear: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        totalEffortPrevMaintenance: [null],
        totalMaterialCost: [null],
        totalServiceCost: [null],
        totalEffortPrevMaintenancePcsNeo: [null],
        totalMaterialCostPcsNeo: [null],
        totalServiceCostPcsNeo: [null],
        individualIuWTasksTotalEffort: [null],
        individualIuWTasksTotalMaterialCost: [null],
        individualIuWTasksTotalServiceCost: [null]
      }),
      correctiveMaintenance: this.formBuilder.group({
        correctiveMainServiceTimesRb: [null, Validators.required],
        checkBoxalternativeLumpSum: [null, Validators.required],
        alternativeValuePerYear: [null, Validators.required],
        curCostServiceLevel: [null, Validators.required],
        checkBoxRepairTime: [null, Validators.required],
        cbIsRepairTimeSelectable: [null, Validators.required]
      }),
      systemUpgrades: this.formBuilder.group({
        cbHwUSwUpgrade: [null, Validators.required],
        upgradeCycleYears: [null, Validators.required],
        cBSecondHwSwUpgr: [null, Validators.required],
        firstUpgradeOverall: [null, Validators.required],
        secondUpgradeOverall: [null, Validators.required],
        thirdUpgradeOverall: [null, Validators.required],
        otherCostsRentalAso: [null, Validators.required],
        shippingCostPC: [null, Validators.required],
        cBfirstUpgrade: [null, Validators.required],
        lcsStartfirstUpgrade: [null, Validators.required],
        firstUpgradeDropdown: [null, Validators.required],
        rbNetworkComponentReplace: [null, Validators.required],
        textFieldFirstUpgrade: [null, Validators.required],
        hmiAdaptionOptionSelection: [null, Validators.required],
        hmiAdaptionOption1InitialEng: [null, Validators.required],
        hmiAdaptionOption1ShareForAdaption: [null, Validators.required],
        hmiAdaptionOption1AdaptionCost: [null, Validators.required],
        hmiAdaptionOptio2NoOfPictures: [null, Validators.required],
        hmiAdaptionOptio2CostPerPicture: [null, Validators.required],
        hmiAdaptionOption2AdaptionCost: [null, Validators.required],
        replaceNetworkComponentsInYear: [null, Validators.required],
        networkComponentsReplacementEffort: [null, Validators.required],
        relevantNetworkComponentsNeo: [null, Validators.required],
        relevantNetworkComponentsPcs7: [null, Validators.required],
        pcsNeoEvolution: [null],
        yearOfStartFix: [null],
        lcsContractLastYear: [null],
        lcsContractDuration: [null],
        startWithPcs7OrPcsNeo: [null],
        osClientsPcs7: [null, Validators.required],
        osClientsPcsNeoEvolution: [null, Validators.required],
        osClientsPcsNeo: [null, Validators.required],
        engineeringStationsPcs7: [null, Validators.required],
        engineeringStationsPcsNeoEvolution: [null, Validators.required],
        engineeringStationsPcsNeo: [null, Validators.required],
        fatIntegrationTestClientServerPcs7: [null, Validators.required],
        fatIntegrationTestClientServerPcsNeoEvolution: [null, Validators.required],
        fatIntegrationTestClientServerPcsNeo: [null, Validators.required],
        osServerInstallationPcs7: [null, Validators.required],
        osServerInstallationPcsNeoEvolution: [null, Validators.required],
        osServerInstallationPcsNeo: [null, Validators.required],
        phServerInstallationPcs7: [null, Validators.required],
        phServerInstallationPcsNeoEvolution: [null, Validators.required],
        phServerInstallationPcsNeo: [null, Validators.required],
        batchServerInstallationPcs7: [null, Validators.required],
        batchServerInstallationPcsNeoEvolution: [null, Validators.required],
        batchServerInstallationPcsNeo: [null, Validators.required],
        rcServerInstallationPcs7: [null, Validators.required],
        rcServerInstallationPcsNeoEvolution: [null, Validators.required],
        rcServerInstallationPcsNeo: [null, Validators.required],
        webServerInstallationPcs7: [null, Validators.required],
        fatIntegrationTestEsServerPcs7: [null, Validators.required],
        fatIntegrationTestEsServerPcsNeoEvolution: [null, Validators.required],
        fatIntegrationTestEsServerPcsNeo: [null, Validators.required],
        updateDocumentationPcs7: [null, Validators.required],
        updateDocumentationPcsNeoEvolution: [null, Validators.required],
        updateDocumentationPcsNeo: [null, Validators.required],
        fatApprovalDocumentationPcs7: [null, Validators.required],
        fatApprovalDocumentationPcsNeoEvolution: [null, Validators.required],
        fatApprovalDocumentationPcsNeo: [null, Validators.required],
        adaptionOfActualDataOnSitePcs7: [null, Validators.required],
        adaptionOfActualDataOnSitePcsNeoEvolution: [null, Validators.required],
        adaptionOfActualDataOnSitePcsNeo: [null, Validators.required],
        updateFirmwarePcs7: [null, Validators.required],
        updateFirmwarePcsNeoEvolution: [null, Validators.required],
        updateFirmwarePcsNeo: [null, Validators.required],
        onsiteInstallationPcs7: [null, Validators.required],
        onsiteInstallationPcsNeoEvolution: [null, Validators.required],
        onsiteInstallationPcsNeo: [null, Validators.required],
        saveReorganizePcs7: [null, Validators.required],
        saveReorganizePcsNeoEvolution: [null, Validators.required],
        saveReorganizePcsNeo: [null, Validators.required],
        runtimeDataMigrationPcs7: [null, Validators.required],
        runtimeDataMigrationPcsNeoEvolution: [null, Validators.required],
        runtimeDataMigrationPcsNeo: [null, Validators.required],
        formAdaptionPcs7: [null, Validators.required],
        formAdaptionPcsNeoEvolution: [null, Validators.required],
        formAdaptionPcsNeo: [null, Validators.required],
        createProjectBackupPcs7: [null, Validators.required],
        createProjectBackupPcsNeoEvolution: [null, Validators.required],
        createProjectBackupPcsNeo: [null, Validators.required],
        osServerOfflineDataPcs7: [null, Validators.required],
        osServerOfflineDataPcsNeoEvolution: [null, Validators.required],
        osServerOfflineDataPcsNeo: [null, Validators.required],
        createBackupPerPCPcs7: [null, Validators.required],
        createBackupPerPCPcsNeoEvolution: [null, Validators.required],
        createBackupPerPCPcsNeo: [null, Validators.required],
        osClientPcs7: [null, Validators.required],
        osClientPcsNeoEvolution: [null, Validators.required],
        osClientPcsNeo: [null, Validators.required],
        onSiteSITPcs7: [null, Validators.required],
        onSiteSITPcsNeoEvolution: [null, Validators.required],
        onSiteSITPcsNeo: [null, Validators.required],
        swBatchServerInstallationPcs7: [null, Validators.required],
        swBatchServerInstallationPcsNeoEvolution: [null, Validators.required],
        swBatchServerInstallationPcsNeo: [null, Validators.required],
        testEffortPcs7: [null, Validators.required],
        testEffortPcsNeoEvolution: [null, Validators.required],
        testEffortPcsNeo: [null, Validators.required],
        swBatchClientPcs7: [null, Validators.required],
        swBatchClientPcsNeoEvolution: [null, Validators.required],
        swBatchClientPcsNeo: [null, Validators.required],
        failSafeCPUPcs7: [null, Validators.required],
        failSafeCPUPcsNeoEvolution: [null, Validators.required],
        failSafeCPUPcsNeo: [null, Validators.required],
        projectManagementPcs7: [null, Validators.required],
        projectManagementPcsNeoEvolution: [null, Validators.required],
        projectManagementPcsNeo: [null, Validators.required],
        failSafeSystemPcs7: [null, Validators.required],
        failSafeSystemPcsNeoEvolution: [null, Validators.required],
        failSafeSystemPcsNeo: [null, Validators.required],
        assetManagementPcs7: [null, Validators.required],
        assetManagementPcsNeoEvolution: [null, Validators.required],
        assetManagementPcsNeo: [null, Validators.required],
        firmwareUpdatePcs7: [null, Validators.required],
        firmwareUpdatePcsNeoEvolution: [null, Validators.required],
        firmwareUpdatePcsNeo: [null, Validators.required],
        processDeviceManagementPcs7: [null, Validators.required],
        processDeviceManagementPcsNeoEvolution: [null, Validators.required],
        processDeviceManagementPcsNeo: [null, Validators.required],
        routeControlPcs7: [null, Validators.required],
        routeControlPcsNeoEvolution: [null, Validators.required],
        routeControlPcsNeo: [null, Validators.required],
        userManagementPcs7: [null, Validators.required],
        userManagementPcsNeoEvolution: [null, Validators.required],
        userManagementPcsNeo: [null, Validators.required],
        casPhIsServerPcs7: [null, Validators.required],
        casPhIsServerPcsNeoEvolution: [null, Validators.required],
        casPhIsServerPcsNeo: [null, Validators.required],
        windowsDomainControllerSetupPcs7: [null, Validators.required],
        windowsDomainControllerSetupPcsNeoEvolution: [null, Validators.required],
        windowsDomainControllerSetupPcsNeo: [null, Validators.required],
        webServerPcs7: [null, Validators.required],
        integrationOfOsClientPcs7: [null, Validators.required],
        integrationOfOsClientPcsNeoEvolution: [null, Validators.required],
        integrationOfOsClientPcsNeo: [null, Validators.required],
        safetyMatrixPcs7: [null, Validators.required],
        safetyMatrixPcsNeoEvolution: [null, Validators.required],
        safetyMatrixPcsNeo: [null, Validators.required],
        wSusServerSetupPcs7: [null, Validators.required],
        wSusServerSetupPcsNeoEvolution: [null, Validators.required],
        wSusServerSetupPcsNeo: [null, Validators.required],
        firewallSetupPcs7: [null, Validators.required],
        firewallSetupPcsNeoEvolution: [null, Validators.required],
        firewallSetupPcsNeo: [null, Validators.required],
        pcs7UpgradeYear1: [null], pcs7UpgradeYear2: [null], pcs7UpgradeYear3: [null],
        pcsNeoHwUpgradeYear1: [null], pcsNeoHwUpgradeYear2: [null], pcsNeoHwUpgradeYear3: [null],
        pcsNeoSwUpgradeYear1: [null], pcsNeoSwUpgradeYear2: [null], pcsNeoSwUpgradeYear3: [null],
        replacementEffortPerSupplComponent: [0, Validators.required]
      }),
      sivaasSystemServices: this.formBuilder.group({
        checkBoxRegionalParmaterUpgrade: [null, Validators.required],
        quantityOffSite12: [null],
        effectiveTimeOffSite12: [null],
        hQTimeOffSite12: [null],
        regEstimationOffSite12: [null, Validators.required],
        quantityOffSite18: [null],
        effectiveTimeOffSite18: [null],
        hQTimeOffSite18: [null],
        regEstimationOffSite18: [null, Validators.required],
        quantityOffSite21: [null],
        effectiveTimeOffSite21: [null],
        hQTimeOffSite21: [null],
        regEstimationOffSite21: [null, Validators.required],
        quantityOnSite1: [null],
        effectiveOnSite1: [null],
        hqTimeOnSite1: [null],
        regEstimationOnSite1: [null, Validators.required],
        quantityOnSite2: [null],
        effectiveOnSite2: [null],
        hqTimeOnSite2: [null],
        regEstimationOnSite2: [null, Validators.required],
        quantityOnSiteHWAddons: [null],
        effectiveOnSiteHWAddons: [null],
        hqTimeOnSiteHWAddons: [null],
        regEstimationOnSiteHWAddons: [null, Validators.required],
        quantityOnSite4: [null],
        effectiveOnSite4: [null],
        hqTimeOnSite4: [null],
        regEstimationOnSite4: [null, Validators.required],
        quantityOnSite5: [null],
        effectiveOnSite5: [null],
        hqTimeOnSite5: [null],
        regEstimationOnSite5: [null, Validators.required],
        quantityOnSite6: [null],
        effectiveOnSite6: [null],
        hqTimeOnSite6: [null],
        regEstimationOnSite6: [null, Validators.required]
      }),
      swUpdate: this.formBuilder.group({
        checkBoxSoftwareUpdate: [null],
        yearOfStartFix: [null, Validators.required],
        pcsNeoEvolution: [null],
        startWithPcs7OrPcsNeo: [null], lcsContractDuration: [null],
        pcs7SwUpdateYear1: [null], pcs7SwUpdateYear2: [null], pcs7SwUpdateYear3: [null],
        pcs7SwUpdateYear4: [null], pcs7SwUpdateYear5: [null], pcs7SwUpdateYear6: [null],
        pcs7SwUpdateYear7: [null], pcs7SwUpdateYear8: [null], pcs7SwUpdateYear9: [null],
        pcs7SwUpdateYear10: [null], pcs7SwUpdateYear11: [null], pcs7SwUpdateYear12: [null],
        pcs7SwUpdateYear13: [null], pcs7SwUpdateYear14: [null], pcs7SwUpdateYear15: [null],
        pcs7SwUpdateYear16: [null], pcs7SwUpdateYear17: [null], pcs7SwUpdateYear18: [null],
        pcs7SwUpdateYear19: [null], pcs7SwUpdateYear20: [null], pcs7SwUpdateYear21: [null],
        pcs7SwUpdateYear22: [null], pcs7SwUpdateYear23: [null], pcs7SwUpdateYear24: [null],
        pcs7SwUpdateYear25: [null],
        pcsNeoSwUpdateYear1: [null], pcsNeoSwUpdateYear2: [null], pcsNeoSwUpdateYear3: [null],
        pcsNeoSwUpdateYear4: [null], pcsNeoSwUpdateYear5: [null], pcsNeoSwUpdateYear6: [null],
        pcsNeoSwUpdateYear7: [null], pcsNeoSwUpdateYear8: [null], pcsNeoSwUpdateYear9: [null],
        pcsNeoSwUpdateYear10: [null], pcsNeoSwUpdateYear11: [null], pcsNeoSwUpdateYear12: [null],
        pcsNeoSwUpdateYear13: [null], pcsNeoSwUpdateYear14: [null], pcsNeoSwUpdateYear15: [null],
        pcsNeoSwUpdateYear16: [null], pcsNeoSwUpdateYear17: [null], pcsNeoSwUpdateYear18: [null],
        pcsNeoSwUpdateYear19: [null], pcsNeoSwUpdateYear20: [null], pcsNeoSwUpdateYear21: [null],
        pcsNeoSwUpdateYear22: [null], pcsNeoSwUpdateYear23: [null], pcsNeoSwUpdateYear24: [null],
        pcsNeoSwUpdateYear25: [null]
      }),
      managedSystemServices: this.formBuilder.group({
        yearOfStartFix: [null], lcsContractDuration: [null],
        newMssName: [null],
        newMssDescription: [null],
        newMssCost: [null],
        newMssCostIncreaseRate: [null],
        managedSystemServiceRiskAddition: [null, Validators.required]
      }),
      individualServices: this.formBuilder.group({}),
      ivmForm: this.formBuilder.group({
        ivmPlatform: [null, Validators.required],
        ivmPriceIncreaseRate: [null, Validators.required],
        ivmMargin: [null, Validators.required],
        ivmLcsDiscount: [null, Validators.required],
        ivmAmountOfPackages: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        ivmSelectedPlatform: [null, Validators.required],
        ivmSelectedPlatformMlfb: [null, Validators.required],
        ivmSelectedPlatformGPrice: [null, Validators.required],
        ivmCalculatedGPrice: [null, Validators.required],
        yearOfStartFix: [null], lcsContractDuration: [null],
        ivmCostYear1: [null],
        ivmCostYear2: [null],
        ivmCostYear3: [null],
        ivmCostYear4: [null],
        ivmCostYear5: [null],
        ivmCostYear6: [null],
        ivmCostYear7: [null],
        ivmCostYear8: [null],
        ivmCostYear9: [null],
        ivmCostYear10: [null],
        ivmCostYear11: [null],
        ivmCostYear12: [null],
        ivmCostYear13: [null],
        ivmCostYear14: [null],
        ivmCostYear15: [null],
        ivmSelectedYear1: [null], ivmSelectedYear2: [null], ivmSelectedYear3: [null], ivmSelectedYear4: [null], ivmSelectedYear5: [null],
        ivmSelectedYear6: [null], ivmSelectedYear7: [null], ivmSelectedYear8: [null], ivmSelectedYear9: [null], ivmSelectedYear10: [null],
        ivmSelectedYear11: [null], ivmSelectedYear12: [null], ivmSelectedYear13: [null], ivmSelectedYear14: [null],
        ivmSelectedYear15: [null]
      }),
      mvdiForm: this.formBuilder.group({
        mvdiPriceIncreaseRate: [null, Validators.required],
        mvdiMargin: [null, Validators.required],
        mvdiRiskAddition: [null, Validators.required],
        mvdiYearOfNeoEvolution: [null, Validators.required],
        mvdiServiceContractDuration: [null, Validators.required],
        mvdiLCSDiscount: [null, Validators.required],
        mvdiEvaluierungPcs7Mlfb: [null, Validators.required],
        mvdiDevelopmentPcs7Mlfb: [null, Validators.required],
        mvdiAsServicevertragMlfb: [null, Validators.required],
        mvdiOsServicevertragMlfb: [null, Validators.required],
        mvdiEvaluierungPcsNeoMlfb: [null, Validators.required],
        mvdiASsDevelopmentPcsNeoMflb: [null, Validators.required],
        mvdiOsDevelopmentPcsNeoMlfb: [null, Validators.required],
        mvdiEvaluierungPcs7InitYear: [null, Validators.required],
        mvdiEvaluierungPcs7GPrice: [null, Validators.required],
        mvdiEvaluierungPcs7LcsDiscountCb: [null, Validators.required],
        mvdiEvaluierungPcs7GPriceInclRisk: [null, Validators.required],
        mvdiDevelopmentPcs7InitYear: [null, Validators.required],
        mvdiDevelopmentPcs7GPrice: [null, Validators.required],
        mvdiDevelopmentPcs7GPriceInclRisk: [null, Validators.required],
        mvdiEvaluierungPcsNeoInitYear: [null, Validators.required],
        mvdiEvaluierungPcsNeoGPrice: [null, Validators.required],
        mvdiEvaluierungPcsNeoLcsDiscountCb: [null, Validators.required],
        mvdiEvaluierungPcsNeoGPriceInclRisk: [null, Validators.required],
        mvdiASsDevelopmentPcsNeoInitYear: [null, Validators.required],
        mvdiASsDevelopmentPcsNeoGPrice: [null, Validators.required],
        mvdiASsDevelopmentPcsNeoLcsDiscountCb: [null, Validators.required],
        mvdiASsDevelopmentPcsNeoGPriceInclRisk: [null, Validators.required],
        mvdiOsDevelopmentPcsNeoInitYear: [null, Validators.required],
        mvdiOsDevelopmentPcsNeoGPrice: [null, Validators.required],
        mvdiOsDevelopmentPcsNeoLcsDiscountCb: [null, Validators.required],
        mvdiOsDevelopmentPcsNeoGPriceInclRisk: [null, Validators.required],
        mvdiAsServicevertragInitYear: [null, Validators.required],
        mvdiAsServicevertragFirstExtenstion: [null, Validators.required],
        mvdiAsServicevertragSecondExtension: [null, Validators.required],
        mvdiAsServicevertragGPrice: [null, Validators.required],
        mvdiAsServicevertragLcsDiscountCb: [null, Validators.required],
        mvdiAsServicevertragGPriceInclRisk: [null, Validators.required],
        mvdiOsServicevertragInitYear: [null, Validators.required],
        mvdiOsServicevertragFirstExtension: [null, Validators.required],
        mvdiOsServicevertragSecondExtension: [null, Validators.required],
        mvdiOsServicevertragGPrice: [null, Validators.required],
        mvdiOsServicevertragLcsDiscountCb: [null, Validators.required],
        mvdiOsServicevertragGPriceInclRisk: [null, Validators.required],
        mvdiSecondContractExtensionCb: [null, Validators.required],
        mvdiFirstContractExtensionCb: [null, Validators.required]

      })
    });

    (this.defineServicesForm.get('individualServices') as FormGroup)!.
      addControl('yearOfStartFix', new FormControl(null));

    for (let i = 1; i <= 20; i++){

      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('disServiceService' + ('00' + i).slice(-2), new FormControl(null));
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('disMaterialService' + ('00' + i).slice(-2), new FormControl(null, Validators.required));
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('disCostPerInstanceService' + ('00' + i).slice(-2), new FormControl(null, Validators.required));
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('disCostIncreasePerYearService' + ('00' + i).slice(-2), new FormControl(null, Validators.required));
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('disMarginService' + ('00' + i).slice(-2), new FormControl(null, Validators.required));
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('disServiceCostPerInstanceService' + ('00' + i).slice(-2), new FormControl(null, Validators.required));
    
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('disRiskAdditionService' + ('00' + i).slice(-2), new FormControl(null, Validators.required));
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('disRiskAdditionInclService' + ('00' + i).slice(-2), new FormControl(null, Validators.required));
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('materialsYearTotalService' + ('00' + i).slice(-2), new FormControl(null));
      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('servicesYearTotalService' + ('00' + i).slice(-2), new FormControl(null));

      (this.defineServicesForm.get('individualServices') as FormGroup)!.
        addControl('totalCostYearTotalService' + ('00' + i).slice(-2), new FormControl(null));

      for (let j = 1; j <= 15; j++){
        (this.defineServicesForm.get('individualServices') as FormGroup)!.
          addControl('amountApplicationYear' + j + 'Service' + ('00' + i).slice(-2),
            new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));

        (this.defineServicesForm.get('individualServices') as FormGroup)!.
          addControl('materialsYear' + j + 'Service' + ('00' + i).slice(-2), new FormControl(null));

        (this.defineServicesForm.get('individualServices') as FormGroup)!.
          addControl('servicesYear' + j + 'Service' + ('00' + i).slice(-2), new FormControl(null));
        (this.defineServicesForm.get('individualServices') as FormGroup)!.
          addControl('totalCostYear' + j + 'Service' + ('00' + i).slice(-2), new FormControl(null));

        (this.defineServicesForm.get('individualServices') as FormGroup)!.
          addControl('includedRiskAdditionYear' + j + 'Service' + ('00' + i).slice(-2), new FormControl(null));
      }
    }

    //Add dynamic remote Service Module Controls
    for (let i = 1; i <= uiConfig.remoteServiceModules.maxNumberOfServices; i++){
      (this.defineServicesForm.get('remoteServiceModules') as FormGroup)!.
        addControl('remoteServiceName' + i, new FormControl(null));

      (this.defineServicesForm.get('remoteServiceModules') as FormGroup)!.
        addControl('remoteServiceDescription' + i, new FormControl(null));

      (this.defineServicesForm.get('remoteServiceModules') as FormGroup)!.
        addControl('remoteServiceMLFB' + i, new FormControl(null));

      (this.defineServicesForm.get('remoteServiceModules') as FormGroup)!.
        addControl('remoteServiceQuantity' + i, new FormControl(null));

      (this.defineServicesForm.get('remoteServiceModules') as FormGroup)!.
        addControl('remoteServiceCost' + i, new FormControl(null));

      (this.defineServicesForm.get('remoteServiceModules') as FormGroup)!.
        addControl('remoteServicePeriod' + i, new FormControl(null));

      (this.defineServicesForm.get('remoteServiceModules') as FormGroup)!.
      addControl('remoteServiceSelected' + i, new FormControl(null));

    }

    //Add dynamic MSS Standard
    for (let i = 1; i <= 4; i++){
      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemService' + i + 'Name', new FormControl(null));

      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemService' + i + 'Mlfb', new FormControl(null));

      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemService' + i + 'Description', new FormControl(null));

      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemService' + i + 'IsSelected', new FormControl(null));

      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemService' + i + 'CostInEuro', new FormControl(null));

      for (let j = 1; j <= 25; j++){
        (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
          addControl('managedSystemService' + i + 'Year' + j + 'Selected', new FormControl(null));
      }
    }

    //Add dynamic MSS Individual
    for (let i = 1; i <= 4; i++){
      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemIndService' + i + 'Name', new FormControl(null));

      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemIndService' + i + 'Mlfb', new FormControl(null));

      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemIndService' + i + 'Description', new FormControl(null));

      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemIndService' + i + 'CostIncReate', new FormControl(null));

      (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
        addControl('managedSystemIndService' + i + 'CostInEuro', new FormControl(null));

      for (let j = 1; j <= 25; j++){
        (this.defineServicesForm.get('managedSystemServices') as FormGroup)!.
          addControl('managedSystemIndService' + i + 'Year' + j + 'Selected', new FormControl(null));
      }
    }
  }

  ngOnInit() {
    this.scrollToTop();
    this.readData();
  }

  ngAfterViewInit() {
    try {
      this.remoteServicesForm.fillTableWithServiceModules();
    } catch (error) {
      console.log(error);
    }

    try {
      this.managedSystemServiceForm.fillTableWithServiceModules();
    } catch (error) {
      console.log(error);
    }

    try {
      this.systemUpgradesForm.fillYearArray();
    } catch (error) {
      console.log(error);
    }
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.defineServicesForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    await this.formDataService.fillDataIntoDialog(this.defineServicesForm, formData);

    //await this.swUpdateForm.updateContractTime();


  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.defineServicesForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  async submit(){
    await this.writeData();
  }

  async logInvalidControls(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        this.logInvalidControls(control);
      } else {
        if (control?.invalid) {

          console.log(`Control '${key}' is invalid`);
        }
      }
    });
  }

  scrollToTop(){
    const element = document.getElementById('first-element');
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  async checkNotValid(){

    for (const field in this.defineServicesForm.controls) {
      const subForm = this.defineServicesForm.get(field) as FormGroup;

      for (const subField in subForm.controls) {

        if (!subForm.get(subField)!.valid){
          console.log(subField);
        }
      }
    }

  }


}
