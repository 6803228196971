import { AfterViewInit, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-system-upgrades',
  templateUrl: './system-upgrades.component.html',
  styleUrls: ['./system-upgrades.component.scss']
})
export class SystemUpgradesComponent implements AfterViewInit {
  isThisSectionVisible = false;
  yearsArray!: number[];
  lcsStartYear!: number;
  lcsContractDuration!: number;
  pcsNeoEvolution!: number;
  startWithPcs7OrPcsneo!: string;

  // Arrays for selected Checkboxs
  selectedPcs7Upgrades: boolean[] = [];
  selectedNeoHWUpgrades: boolean[] = [];
  selectedNeoSWUpgrades: boolean[] = [];
  selectedPCS7SwUpdates: boolean[] = [];
  selectedNeoSwUpdates: boolean[] = [];

  toggleVisibility(){
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  public parentForm!: FormGroup;

  constructor(private formDataService: FormDataService) { }

  async ngAfterViewInit(){
    this.pcsNeoEvolution = this.parentForm.get('systemUpgrades')?.get('pcsNeoEvolution')?.value;
    await this.updateContractTime();

    this.fillSelectedYears('pcs7Upgrade', 3);
    this.fillSelectedYears('pcsNeoHwUpgrade', 3);
    this.fillSelectedYears('pcsNeoSwUpgrade', 3);

  }

  yearInNeo(){
    if (
      Number(this.parentForm.get('systemUpgrades')?.get('replaceNetworkComponentsInYear')?.value) >=
        Number(this.parentForm.get('systemUpgrades')?.get('pcsNeoEvolution')?.value) ||
          this.parentForm.get('systemUpgrades')?.get('startWithPcs7OrPcsNeo')?.value === 'PCS neo'){
      return true;
    } else {
      return false;
    }
  }

  getDropdownOptionsOfContractYears(): any[] {
    const options = [];
    const contractStartAsNumber = Number(this.parentForm.get('systemUpgrades')?.get('yearOfStartFix')?.value);
    const endYearAsNumber = Number(this.parentForm.get('systemUpgrades')?.get('lcsContractLastYear')?.value);

    for (let i = contractStartAsNumber; i <= endYearAsNumber; i++) {
      options.push({ value: i.toString() });
    }

    return options;
  }

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  async updateContractTime(){
    this.lcsStartYear = Number(this.parentForm.get('swUpdate')?.get('yearOfStartFix')?.value);
    this.lcsContractDuration = Number(this.parentForm.get('swUpdate')?.get('lcsContractDuration')?.value.value);
    this.yearsArray = Array.from({
      length: this.lcsContractDuration
    }, (_, i) => Number(this.lcsStartYear) + i);

    this.pcsNeoEvolution = Number(this.parentForm.get('swUpdate')?.get('pcsNeoEvolution')?.value);
    this.startWithPcs7OrPcsneo = this.parentForm?.get('swUpdate')?.get('startWithPcs7OrPcsNeo')?.value?.value;
  }

  public fillYearArray(){
    this.lcsStartYear = Number(this.parentForm.get('systemUpgrades')?.get('yearOfStartFix')?.value);
    this.lcsContractDuration = Number(this.parentForm.get('systemUpgrades')?.get('lcsContractDuration')?.value.value);
    this.pcsNeoEvolution = Number(this.parentForm.get('systemUpgrades')?.get('pcsNeoEvolution')?.value);
    this.yearsArray = Array.from({
      length: this.lcsContractDuration
    }, (_, i) => Number(this.lcsStartYear) + i);

  }

  fillSelectedYears(varBaseName: string, amountOfPossibleSelectedYears: number){

    const tempIndices = [];

    for (let i = 0; i <= amountOfPossibleSelectedYears; i++){
      const yearValue = Number(this.parentForm.get('systemUpgrades')?.get(varBaseName + 'Year' + (i + 1))?.value);

      if (!isNaN(yearValue)) {
        const index = this.yearsArray.indexOf(yearValue);
        tempIndices.push(index);
      }
    }


    // Check if years are valid regarding PCS neo Evolution year. Only then checkboxes are selected
    switch (varBaseName) {
      case 'pcs7Upgrade':
        tempIndices.forEach(index => {
          if (this.yearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution == 0){
            this.selectedPcs7Upgrades[index] = true;
          } else {
            this.selectedPcs7Upgrades[index] = false;
          }
        });
        break;
      case 'pcsNeoHwUpgrade':
        tempIndices.forEach(index => {
          if (this.yearsArray[index] > this.pcsNeoEvolution){
            this.selectedNeoHWUpgrades[index] = true;
          } else {
            this.selectedNeoHWUpgrades[index] = false;
          }
        });
        break;
      case 'pcsNeoSwUpgrade':
        tempIndices.forEach(index => {
          if (this.yearsArray[index] > this.pcsNeoEvolution){
            this.selectedNeoSWUpgrades[index] = true;
          } else {
            this.selectedNeoHWUpgrades[index] = false;
          }
        });
        break;
      case 'pcs7SwUpdate':
        tempIndices.forEach(index => {
          if (this.yearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution == 0) {
            this.selectedPCS7SwUpdates[index] = true;
          } else {
            this.selectedPCS7SwUpdates[index] = false;
          }
        });
        break;
      case 'pcsNeoSwUpdate':
        tempIndices.forEach(index => {
          if (this.yearsArray[index] > this.pcsNeoEvolution){
            this.selectedNeoSwUpdates[index] = true;
          } else {
            this.selectedNeoSwUpdates[index] = false;
          }
        });
        break;
    }


  }

}
