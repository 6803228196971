import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-import-calculation-data-modal',
  templateUrl: './import-calculation-data-modal.component.html',
  styleUrls: ['./import-calculation-data-modal.component.scss']
})
export class ImportCalculationDataModalComponent implements OnInit {
  @ViewChild('fileSelection', { static: true })
  fileSelection: ElementRef<HTMLInputElement> | undefined;

  calculationDataFile: File | undefined;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {}

  // Speichert die ausgewählte Datei
  storeCalculationDataFile() {
    this.calculationDataFile = this.fileSelection?.nativeElement.files?.item(0) || undefined;
  }

  // Liest die Datei ein und importiert die JSON-Daten
  importData() {
    if (!this.calculationDataFile) {
      console.error('Keine Datei ausgewählt!');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      try {
        const jsonData = JSON.parse(reader.result as string);
        console.log('JSON-Daten importiert:', jsonData);
        
        // Hier kannst du die importierten Daten weiter verarbeiten

        // Daten erfolgreich importiert
        this.modalRef.content.importedData = jsonData;
        this.modalRef.hide();
      } catch (e) {
        console.error('Fehler beim Einlesen der Datei:', e);
      }
    };
    reader.readAsText(this.calculationDataFile);
  }
}
