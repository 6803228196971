<form [formGroup]="parentForm">
    <div class="row" style="margin-bottom: 1em;">
      <div class="col-lg-6 col-md-4 col-sm-12 col-xs-12">
        <!-- Leere Spalte, falls benötigt -->
      </div>
  
      <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12 d-flex justify-content-end">
        <button class="btn btn-primary mr-2" (click)="callDownloadData()">Save Regional Factors</button>
        <button class="btn btn-secondary" (click)="importData()">Import Regional Factors</button>
      </div>
    </div>
  </form>