import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImportCalculationDataModalComponent } from 'src/app/modals/import-calculation-data-modal/import-calculation-data-modal.component';
import { DataTransferService } from 'src/app/services/data-transfer.service';

@Component({
  selector: 'app-save-regional-factors',
  templateUrl: './save-regional-factors.component.html',
  styleUrls: ['./save-regional-factors.component.scss']
})
export class SaveRegionalFactorsComponent implements OnInit {

  @Input()
  public parentForm!: FormGroup;

  @Output()
  downloadData: EventEmitter<any> = new EventEmitter();

  @Output()
  readData: EventEmitter<any> = new EventEmitter();
  

  constructor(private modalRef: BsModalRef, private modalService: BsModalService, private dataTransferService: DataTransferService) { }

  ngOnInit(): void {
  }

  callDownloadData() {
    this.downloadData.emit();
  }

  importData() {
    this.modalRef = this.modalService.show(ImportCalculationDataModalComponent, { });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.content.importedData = false;

    this.modalRef.onHidden.subscribe(async (result: any) => {
      if (this.modalRef.content.importedData){
        await this.dataTransferService.writeDataToExcel(JSON.stringify(this.modalRef.content.importedData));

        this.readData.emit();      
      }
    });
  
  }

}
