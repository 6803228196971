<si-newton-section>

  <app-navigation [currentSectionForm]=resultOverviewForm></app-navigation>
  <form [formGroup]="resultOverviewForm" class="col-md-12" style="align-items: center;">

    <tabset>
      <tab heading="Cost Overview">
        <app-cost-overview [parentForm]="resultOverviewForm" [costOverviewData]="costOverviewData"></app-cost-overview>

      </tab>

      <tab heading="Price Overview">
        <app-price-overview [parentForm]="resultOverviewForm" [priceOverviewData]="priceOverviewData"></app-price-overview>

      </tab>
    </tabset>
    <app-result-file [parentForm]="resultOverviewForm"></app-result-file>
  </form>
</si-newton-section>
