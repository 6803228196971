const baseUrl = '';

export const environment = {
  production: false,
  baseUrl: '',
  apiUrl: '/excel-api',
  auth: {
    domain: 'siemens-qa-00169.eu.auth0.com',
    clientId: 'fJkpRiz7ip3Dgj6VTmnmx2FINJj3nXu2',
    authorizationParams: {
      audience: 'excel-calculator-dev',
      redirect_uri: window.location.origin,
    },
    errorPath: '/error',
    audience: 'excel-calculator-dev'
  },
  cacheLocation: 'localstorage',
  httpInterceptor: {
    allowedList: [`${baseUrl}/*`],
  }
};

