<div class="modal-header">
    <h4 class="modal-title pull-left">Import Calculation Data</h4>
  
</div>
<div #modalBody class="modal-body">

    <div class="row">
        <!-- <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12">

        </div> -->
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="btn btn-primary" style="margin: auto;">
              Select new Calculation Data File
              <span class="newton-search"></span>
              <input #fileSelection type="file" accept=".txt" (change)="storeCalculationDataFile()">
            </label>
          </div>
    </div>

</div>
<div class="modal-footer">
<button type="button" class="btn btn-warning" (click)="modalRef.hide()">Cancel</button>
<button type="button" class="btn btn-success" [disabled]="calculationDataFile === undefined"
    (click)="importData()">Import Data</button>
</div>
  