/* eslint-disable max-len */
/* eslint-disable guard-for-in */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { ConstRestData } from '../../const-data/rest';

@Component({
  selector: 'app-regional-factors',
  templateUrl: './regional-factors.component.html',
  styleUrls: ['./regional-factors.component.scss']
})
export class RegionalFactorsComponent implements OnInit {
  REST_URLS = ConstRestData.restUrls;
  regionalFactorsForm: FormGroup;

  @ViewChild('first-element', { static: false }) firstElement!: ElementRef;

  constructor(private formBuilder: FormBuilder, private dataTransferService: DataTransferService,
    private formDataService: FormDataService) {
    this.regionalFactorsForm = this.formBuilder.group({
      costFactorsInternal: this.formBuilder.group({
        serviceCostFactor: [null, Validators.compose([Validators.required, Validators.min(0)])],
        engineeringCostInhouse: [null, Validators.compose([Validators.required, Validators.min(0)])]
      }),
      regionalDiscounts: this.formBuilder.group({
        pcs7pcsNeoIpcsRegionalDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        netWorkSwitcdhesScalanes: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcs7SUSRegionalDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        windowsDomainController: [null, Validators.compose([Validators.required, Validators.min(0)])],
        hostThinClientsVmTemplates: [null, Validators.compose([Validators.required, Validators.min(0)])],
        serviceContractsvCenterServer: [null, Validators.compose([Validators.required, Validators.min(0)])],
        firewalls: [null, Validators.compose([Validators.required, Validators.min(0)])],
        wsusServer: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcsNeoSwDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcs7SwMainDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcs7LssDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcsNeoSwMainDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        
      }),
      riskFactors: this.formBuilder.group({
        serviceRisk: [null, Validators.compose([Validators.required, Validators.min(0)])],
        upgradeRisk: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcsNeoEvolutionRisk: [null, Validators.compose([Validators.required, Validators.min(0)])]
      }),
      serviceParameters: this.formBuilder.group({
        workingHrsPerDay: [null, Validators.compose([Validators.required, Validators.min(0)])]
      }),
      costIncreaseRates: this.formBuilder.group({
        pcs7SUSCostIncrease: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcs7ThirdParty: [null, Validators.compose([Validators.required, Validators.min(0)])],
        swAddonsCostIncrease: [null, Validators.compose([Validators.required, Validators.min(0)])],
        hwAddonsCostIncrease: [null, Validators.compose([Validators.required, Validators.min(0)])],
        networkCompScal: [null, Validators.compose([Validators.required, Validators.min(0)])],
        itInfraStructurIncreasePerYear: [null, Validators.compose([Validators.required, Validators.min(0)])],
        serviceCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        otherCosts: [null, Validators.compose([Validators.required, Validators.min(0)])],
        sivaasVMs: [null, Validators.compose([Validators.required, Validators.min(0)])],
        hpHostThinClients: [null, Validators.compose([Validators.required, Validators.min(0)])],
        serviceContractsIncreaseCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        vCenterServer: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcsNeoSwCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcs7LssCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcs7SwMainCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])]
      }),
      exchangeFactors: this.formBuilder.group({
        cBCurrencyConversion: [null, Validators.required],
        targetCurrency: [null, Validators.required],
        currencyExchangeFactor: [null, Validators.compose([Validators.required, Validators.min(0)])]
      }),
      pricingFactors: this.formBuilder.group({
        cBPriceCalculationRequested: [null, Validators.required],
        grossMarginPCS7_IPC: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginServices: [null, Validators.compose([Validators.required, Validators.min(0)])],
        customerDiscountsHWLevel1: [null, Validators.compose([Validators.required, Validators.min(0)])],
        customerDiscountsHWLevel2: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginOthers: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginPCS7SUS: [null, Validators.compose([Validators.required, Validators.min(0)])],
        customerDiscountsSWLevel1: [null, Validators.compose([Validators.required, Validators.min(0)])],
        customerDiscountsSWLevel2: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginOnCall: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginItInfra: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginHwAddons: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginSwAddons: [null, Validators.compose([Validators.required, Validators.min(0)])],
        minTotalMarginAdminStandard: [null, Validators.compose([Validators.required, Validators.min(0)])],
        calculativeInterestRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginSIVaasAddons: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginHpHardware: [null, Validators.compose([Validators.required, Validators.min(0)])],
        negoationMargin: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossSivaasServiceContracts: [null, Validators.compose([Validators.required, Validators.min(0)])],
        grossMarginVCenterServer: [null, Validators.compose([Validators.required, Validators.min(0)])],
        pcsNeoMarginSwMaintPackage: [null, Validators.compose([Validators.required, Validators.min(0)])],
        bailOutDemandHW: [null, Validators.compose([Validators.required, Validators.min(0)])],
        bailOutDemandSW: [null, Validators.compose([Validators.required, Validators.min(0)])]
      })
    });
  }

  ngOnInit() {
    this.readData();
  }

  async submit(){
    await this.writeData();
  }

  logInvalidControls(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        this.logInvalidControls(control);
      } else {
        if (control?.invalid) {

          console.log(`Control '${key}' is invalid`);
        }
      }
    });
  }

  async downloadData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.regionalFactorsForm, true);
    const jsonString = JSON.stringify(jsonData, null, 2);

    const blob = new Blob([jsonString], { type: 'text/plain' }); 
    const projectName = sessionStorage.getItem('SelectedProjectName');

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = projectName + '-regional-factors.txt'; 
    a.click();

    window.URL.revokeObjectURL(url);
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.regionalFactorsForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    this.formDataService.fillDataIntoDialog(this.regionalFactorsForm, formData);
  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.regionalFactorsForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  scrollToTop(){
    const element = document.getElementById('first-element');
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
